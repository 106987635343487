// @font-face {
//   font-family: "roboto";
//   src: url("../fonts/roboto/roboto-regular/roboto-regular.woff2?v=1.1.0") format("woff2"),
//   url("../fonts/roboto/roboto-regular/roboto-regular.woff?v=1.1.0") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }
// @font-face {
//   font-family: "roboto";
//   src: url("../fonts/roboto/roboto-bold/roboto-bold.woff2") format("woff2"),
//   url("../fonts/roboto/roboto-bold/roboto-bold.woff") format("woff");
//   font-weight: 700;
//   font-style: normal;
// }

// century gothic

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/centurygothic/CenturyGothic.woff2') format('woff2'),
        url('../fonts/centurygothic/CenturyGothic.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/centurygothic/CenturyGothic-Italic.woff2') format('woff2'),
        url('../fonts/centurygothic/CenturyGothic-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/centurygothic/CenturyGothic-Bold.woff2') format('woff2'),
        url('../fonts/centurygothic/CenturyGothic-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/centurygothic/CenturyGothic-BoldItalic.woff2') format('woff2'),
        url('../fonts/centurygothic/CenturyGothic-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

// helvetica

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Light.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Heavy.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Thin.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Medium.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helvetica/HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url('../fonts/helvetica/HelveticaNeueCyr-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
