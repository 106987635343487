html {
  height: 100%;
  box-sizing: border-box;

  overflow-x: hidden;
}

*,*:before,*:after {box-sizing: inherit;}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  font-family: @main-font;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  min-height: 100%;
  background-color: #fff;

  overflow-x: hidden;
}

.main-content {
  flex-grow: 1;
}

/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1320px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}
.container-full {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.std-section {
  // padding-top: 50px;
  // padding-bottom: 50px;
  padding-left: 70px;
  padding-right: 70px;
  @media (min-width: 1025px) and (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 1px) and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.full-section {
  padding-left: 0;
  padding-right: 0;
}

.dark {
  background-color: #f4f4f4;
}

/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button { cursor: pointer; }
address { font-style:normal; }
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content {
  & .mfp-close {

    opacity: 1;

    top: -44px;

    & .icon {
      width: 25px;
      height: 25px;
      fill: @primary-color;
    }
  }
}


.posr {
  position: relative;
}

/**
* footer webmotor
*/
// .webmotor {
//   & span {
//     color: #000;
//   }
//   & a {
//     color: #000;
//     text-decoration: underline;
//     transition: 0.15s color ease-in;
//   }
// }

.page-head {
  margin-bottom: 20px;
}
