/**
* form style
*/
.site-form {
  // label
  @form-label-text-color:         #565a5f;
  @form-label-font-size:          14px;
  @form-label-font-weight:        400;
  // elements (input, textarea)
  @form-input-height:             40px;
  @form-elements-border-radius:   0;
  @form-textarea-min-height:      110px;
  @form-textarea-resize:          vertical; //  none | both | horizontal | vertical | inherit

  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  &-wrapper {
    border: 2px solid @form-focus-control-color;
    margin-bottom: 40px;
  }

  &-element-wrapper {
    position: relative;
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    width: 100%;
    font-size: @form-label-font-size;
    font-weight: @form-label-font-weight;
    color: @form-label-text-color;
  }

  &__input {
    display: block;
    width: 100%;
    height: @form-input-height;
    border: 1px solid @gray--darken;
    border-radius: @form-elements-border-radius;
    background-color: @gray;
    padding-left: 15px;
    padding-right: 15px;

    transition-duration: 0.15s;
    transition-property: background-color;
    transition-timing-function: ease-in;

    &:focus {
      outline: 0;
      border-color: @form-focus-control-color;
      background-color: #fff;
    }
    &:hover {
      background-color: #fff;
    }
  }

  &__textarea {
    display: block;
    width: 100%;
    min-height: @form-textarea-min-height;
    background-color: @gray;
    border: 1px solid @gray--darken;
    border-radius: @form-elements-border-radius;
    padding: 15px;
    resize: @form-textarea-resize;

    transition-duration: 0.15s;
    transition-property: background-color;
    transition-timing-function: ease-in;

    &:focus {
      border-color: @form-focus-control-color;
      outline: 0;
      background-color: #fff;
    }

    &:hover {
      background-color: #fff;
    }
  }

  &__input.error {
    border-color: @form-error-border-color;
    background-color: @form-error-background-color;
    &:focus {
      outline: 0;
    }
  }

  & label.error {
    left: @grid-gutter-width / 2;
    font-size: 0.85rem;
    line-height: 1.2;
    color: @form-error-border-color;
  }

}


/**
* checkbox
*/
.site-form {

  &-row--checkbox {
    position: relative; /* for label with error */
    display: flex;
    margin-bottom: 50px;

    & label.error {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }

  &__label-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    min-width: 21px;
    border-radius: 3px;
    border: 1px solid @gray--darken;
    background-color: @gray;
    padding: 3px;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.15s border-color ease-in;
  }

  &__icon-checked {
    width: 20px;
    height: 20px;
    fill: @primary-color;
    transition: 0.15s opacity ease-in;
    opacity: 0;
  }

  &__input-checkbox {
    &:checked {
      & ~ .site-form__label-checkbox .site-form__icon-checked {
        opacity: 1;
      }
    }
    &:focus {
      & ~ .site-form__label-checkbox {
        border-color: @form-focus-control-color;
      }
    }
    &.error ~ .site-form__label-checkbox{
      border-color: @form-error-border-color;
      background-color: @form-error-background-color;
    }
  }

  &-checkbox-text {
    font-size: 12px;
    color: #818080;
    font-weight: 300;

    & a {
      font-size: 12px;
    }
  }

  &-checkbox-text__link {
    color: @link-color;
    text-decoration: underline;
    &:hover {
      color: @link-hover;
    }
  }
}

#contacts-callback {
  border: none;
  & .site-form {
    max-width: 680px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .site-form__button-submit,
  .site-form__button-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content .site-form,
  .site-form {
    padding-top: 40px;
  }
}
