.objects {

    &-section {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    &__top {

        position: relative;
        margin-left: 0;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 0;
            height: 100%;
            background-color: #fff;
            padding-right: 100%;
            z-index: 333;
        }

    }

    &__info {

        position: relative;
        left: -10px;

        transition: opacity ease-in-out 0.5s;

        @media (max-width: 1023px) {
            margin-bottom: 20px;
            left: 0;
        }

        &_heading {

            margin-bottom: 20px;

        }

        &_location {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-bottom: 30px;

            &-icon {

                width: 20px;
                height: 20px;

                margin-right: 10px;

                & svg {

                    display: block;

                    width: 20px;
                    height: 20px;

                    fill: #000;

                }

            }

            &-adress, & a {
                
                font-size: 18px;
                font-weight: 300;
                color: #3D8FE9;
                text-decoration: underline;

                transition: all ease-in-out 0.1s;

                outline: none;

                @media (max-width: 767px) {

                    font-size: 16px;

                }

                &:hover {

                    color: @primary-color-2;
                    text-decoration: none;

                }

            }

        }

        &_text {

            & .content {

                margin-bottom: 40px;

                & p {

                    font-weight: 300;

                }

            }

            

        }

        &_stats {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            @media (max-width: 579px) {

                flex-direction: column;
                align-items: flex-start;

            }

            &-item {

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                margin-right: 40px;

                @media (max-width: 579px) {
                    
                    margin-bottom: 16px;
                    margin-right: 0;

                }

                & .icon {

                    width: 30px;
                    height: 30px;

                    margin-right: 10px;

                    & svg {

                        display: block;

                        width: 30px;
                        height: 30px;

                        fill: #000;

                    }

                }

                & .value {

                    

                }

            }

        }

    }

    &__slider {

        width: 100%;

        overflow: visible;

        & .swiper-lazy:not(.swiper-lazy-loaded) {

            // max-height: 512px;

            @media (max-width: 1023px) {
                
                max-height: 0;

            }

        }

        &_wrapper {
            
            position: relative;
            
            width: 125%;

        }

        &_item {

            display: block;

            position: relative;

            overflow: hidden;

            padding-bottom: 53%;



            &:before {
                
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.5);

                transition: background ease-in-out 0.1s;

            }

            &.swiper-slide-active {

                &:before {

                    background-color: transparent;

                }

            }

            & img {

                position: absolute;

                display: block;

                width: 100%;
                max-width: 100%;

                // max-height: 512px;

            }

        }

    }

    &__controls {

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width: 1023px) {

            padding-top: 20px;

        }

        @media (max-width: 579px) {

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

        }

        &_download {

            margin-right: auto;
            position: relative;
            left: -10px;

            @media (max-width: 1024px) {
              left: 0;
            }

            @media (max-width: 579px) {

                order: 3;

                margin-right: 0;

            }

        }

        &_counter {
            
            position: static;

            width: auto;

            color: #626262;

            margin-left: 35px;

            @media (max-width: 1023px) {

                margin-left: 0;

            }

            @media (max-width: 579px) {

                order: 1;

                margin-bottom: 10px;

            }

        }

        &_arrows {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;

            margin-left: 35px;

            @media (max-width: 579px) {

                order: 2;

                margin-bottom: 16px;
                margin-left: 0;

            }

            & .prev, & .next {

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: center;
                align-items: center;

                width: 60px;
                height: 60px;

                background-color: #fff;

                -webkit-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
                -moz-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
                box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);

                cursor: pointer;

            }

            & svg {

                display: block;

                width: 35px;
                height: 35px;

                fill: #7f7f7f;

                opacity: 1;

            }

            & .prev {

                margin-right: 20px;

                & svg {

                    transform: rotate(180deg);

                }               

            }

            & .next {

                

            }           

        }

        &_backwards {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            opacity: 1;

            transition: opacity ease-in-out 0.1s;

            @media (max-width: 1023px) {
                
                display: none;

            }

            &-icon {

                width: 20px;
                height: 20px;

                margin-right: 10px;

                & svg {

                    display: block;

                    width: 20px;
                    height: 20px;

                    fill: @primary-color;

                }

            }

            &-link, & a {
                
                font-size: 18px;
                font-weight: 300;
                color: @primary-color;
                text-decoration: underline;

                transition: all ease-in-out 0.1s;

                outline: none;

                @media (max-width: 767px) {

                    font-size: 16px;

                }

                &:hover {

                    color: @primary-color-2;
                    text-decoration: none;

                }

            }

        }

    }

    &__map {

        margin-bottom: 20px;

        &_wrapper {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;

            width: 100%;
            height: 610px;

            @media (max-width: 1023px) {

                flex-direction: column;
                height: auto;

            }

        }       

        &_controls {

            position: relative;

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            
            width: 20%;
            min-width: 270px;
            height: 610px;

            @media (min-width: 768px) and (max-width: 1023px) {

                order: 2;

                flex-direction: row;

                width: 100%;
                height: auto;

            }

            @media (min-width: 320px) and (max-width: 768px) {

                order: 2;

                flex-direction: column;
                width: 100%;
                height: auto;

            }

            &-item {

                position: relative;

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                width: 100%;
                
                font-weight: 300;
                color: #fff;

                background-color: #4a5459;
                border-bottom: 1px solid #70787c;

                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 40px;
                padding-right: 30px;

                cursor: pointer;

                transition: all ease-in-out 0.1s;

                @media (min-width: 768px) and (max-width: 1023px) {

                    border-bottom: none;

                    border-right: 1px solid #70787c;

                    height: auto !important;

                }

                @media (min-width: 320px) and (max-width: 767px) {

                    height: auto !important;

                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 30px;
                    padding-right: 20px;

                }

                &:last-child {

                    border-bottom: none;

                    @media (max-width: 1023px) {

                        border-right: none;

                    }

                }

                & .heading {

                    font-size: 25px;
                    margin-bottom: 10px;

                    @media (max-width: 1023px) {

                        font-size: 20px;

                    }

                }

                & .caption {

                    align-self: stretch;

                    @media (max-width: 1023px) {

                        font-size: 14px;

                    }

                }

                &:hover {

                    background-color: #2c363b;

                }

                &.active {

                    background-color: #2c363b;

                    &:before {

                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;

                        width: 17px;
                        height: 32px;

                        background-image: url('../img/objects/objects__map_marker.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center left;

                        @media (min-width: 768px) and (max-width: 1023px) {

                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%) rotate(90deg);

                        }

                    }

                }

            }

        }

        &_render {

            position: relative;

            width: 80%;
            height: 610px;

            @media (min-width: 580px) and (max-width: 1023px) {

                order: 1;

                width: 100%;
                height: 400px;

            }

            @media (min-width: 320px) and (max-width: 579px) {

                order: 1;

                width: 100%;
                height: 320px;

            }
            &-imglink {

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: center;
                align-items: center;

                height: 100%;

            }

            &-item {
                position: relative;

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: center;
                align-items: center;

                width: 100%;
                height: 610px;

                background-color: #f2f2f2;

                @media (min-width: 580px) and (max-width: 1023px) {

                    height: 400px;

                }

                @media (min-width: 320px) and (max-width: 579px) {

                    height: 320px;

                }

                & img {
                    
                    display: block;
                    width: 90%;

                    @media (max-width: 1023px) {

                        width: auto;
                        max-width: 90%;
                        height: 90%;

                    }

                }

                & .expand {

                    position: absolute;
                    top: 0;
                    right: 0;

                    padding-right: 80px;

                    &:before {

                        content: '';
                        position: absolute;
                        top: -2px;
                        right: -2px;

                        width: 60px;
                        height: 60px;

                        background-color: @primary-color-2;

                    }

                    &:after {

                        content: '';
                        position: absolute;
                        top: 17px;
                        right: 15px;

                        width: 23px;
                        height: 23px;

                        background-image: url('../img/objects/objects__map_expand.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;

                        z-index: 333;

                    }

                }

            }

        }

    }

    &__stats {
        
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 40px;
        padding-right: 40px;

        margin-bottom: 20px;

        @media (max-width: 579px) {

            padding-left: 0;
            padding-right: 0;

        }

        &_list {

            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            height: 100%;

            &-item {

                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                font-weight: 300;

                border-top: 1px solid #d7d7d7;

                padding-top: 20px;
                padding-bottom: 20px;

                @media (max-width: 1023px) {

                    font-size: 14px;

                    border-top: none;
                    border-bottom: 1px solid #d7d7d7;

                    padding-top: 10px;
                    padding-bottom: 10px;

                }

                @media (max-width: 374px) {

                    flex-direction: column;
                    justify-content: flex-start;

                }

                &:last-child {
                    border-bottom: 1px solid #d7d7d7;
                    flex-grow: 1;

                    @media (max-width: 1023px) {

                        flex-grow: 0;

                    }
                }

                & .property {

                    width: 50%;
                    max-width: 50%;
                    flex-basis: 50%;

                    font-weight: 700;

                }

                & .value {

                    width: 50%;
                    max-width: 50%;
                    flex-basis: 50%;

                }

            }

        }

    }

    &__form {
        
        color: #fff;
        background-color: @primary-color;

        &_wrapper {

            & .site-form {

                position: relative;
                
                @media (max-width: 579px) {

                    padding-right: 20px;
                    padding-left: 20px;

                }

                & label.error {

                  position: relative;
                  bottom: 0;

                }

                & .success {

                    position: absolute;
                    top: 0;
                    left: 0;

                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -ms-flex;
                    display: -o-flex;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
                    height: 100%;
                    
                    font-size: 22px;
                    font-weight: 300;

                    color: #fff;
                    background-color: @primary-color;

                    z-index: 222;

                    display: none;

                    & svg {

                        position: absolute;

                        top: 10px;
                        right: 0;

                        width: 20px;
                        height: 20px;

                        fill: #fff;

                        cursor: pointer;

                    }

                }

            }

            & .site-form__head {

                color: #fff;
                margin-bottom: 40px;

            }

            & .site-form__label {

                position: absolute;
                top: 18px;
                left: 25px;

                display: block;

                width: auto;

                font-size: 16px;
                font-weight: 300;
                color: #afb0b1;

                transition: all ease-in-out 0.2s;
                z-index: 0;

                &.top {

                  top: -19px;
                  left: 2px;
                  color: #fff;
                  font-size: 12px;

                }

            }

            & .error:not(.js-masked-input):not(.site-form__input) {
                color: #ffffff !important;
                font-weight: 500 !important;
                font-style: italic !important;
            }

            & .site-form__input {

                font-family: @main-font;
                font-weight: 300;
                
                height: 60px;

                color: #afb0b1;
                background-color: #F3F3F3;
                border-color: #D6D6D6;

                &::placeholder {
                    color: #afb0b1;
                  }
                  &::-webkit-input-placeholder {
                    color: #afb0b1;
                  }
                  &::-moz-placeholder {
                    color: #afb0b1;
                  }
                  &:-moz-placeholder {
                    color: #afb0b1;
                  }
                  &:-ms-input-placeholder {
                    color: #afb0b1;
                  }
            }

            & .site-form__button-submit {

                width: 100%;

                font-family: @main-font;
                font-weight: 700;

                color: #000;
                background-color: #fff;

                border-width: 1px;
                border-color: #fff;

                @media (max-width: 1023px) {

                    width: auto;

                }

            }

            & .site-form__agreement {
                font-weight: 300;

                @media (max-width: 1023px) {

                    font-size: 14px;

                }
            }

        }

    }

}