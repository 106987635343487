.block-wrapper {
  /**
  * универсальная обертка для блока
  */
  margin-bottom: 40px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.no-padding {
  padding: 0 !important;
  @media only screen and (min-width: 768px) {
    padding: 0 !important; 
  }
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  @media only screen and (min-width: 768px) {
    margin-left: 0 !important; 
    margin-right: 0 !important; 
  }
}

.underline {
  text-decoration: underline;
}

.text-align-center {
  text-align: center;
}

.flex-col {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.faded {

  opacity: 0 !important;

}

section.p {
  &- {
    &0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &1 {
      padding-top: 1vh !important;
      padding-bottom: 1vh !important;
    }
    &2 {
      padding-top: 2vh !important;
      padding-bottom: 2vh !important;
    }
    &3 {
      padding-top: 3vh !important;
      padding-bottom: 3vh !important;
    }
    &4 {
      padding-top: 4vh !important;
      padding-bottom: 4vh !important;
    }
    &5 {
      padding-top: 5vh !important;
      padding-bottom: 5vh !important;
    }
    &6 {
      padding-top: 6vh !important;
      padding-bottom: 6vh !important;
    }
    &7 {
      padding-top: 7vh !important;
      padding-bottom: 7vh !important;
    }
    &8 {
      padding-top: 8vh !important;
      padding-bottom: 8vh !important;
    }
    &9 {
      padding-top: 9vh !important;
      padding-bottom: 9vh !important;
    }
  }
  &t- {
    &0 {
      padding-top: 0 !important;
    }
    &1 {
      padding-top: 1vh !important;
    }
    &2 {
      padding-top: 2vh !important;
    }
    &3 {
      padding-top: 3vh !important;
    }
    &4 {
      padding-top: 4vh !important;
    }
    &5 {
      padding-top: 5vh !important;
    }
    &6 {
      padding-top: 6vh !important;
    }
    &7 {
      padding-top: 7vh !important;
    }
    &8 {
      padding-top: 8vh !important;
    }
    &9 {
      padding-top: 9vh !important;
    }
  }
  &b- {
    &0 {
      padding-bottom: 0 !important;
    }
    &1 {
      padding-bottom: 1vh !important;
    }
    &2 {
      padding-bottom: 2vh !important;
    }
    &3 {
      padding-bottom: 3vh !important;
    }
    &4 {
      padding-bottom: 4vh !important;
    }
    &5 {
      padding-bottom: 5vh !important;
    }
    &6 {
      padding-bottom: 6vh !important;
    }
    &7 {
      padding-bottom: 7vh !important;
    }
    &8 {
      padding-bottom: 8vh !important;
    }
    &9 {
      padding-bottom: 9vh !important;
    }
  }
}
