.loyality {

	&__top {

		position: relative;
		margin-left: 0;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: -100%;
			width: 0;
			height: 100%;
			background-color: #fff;
			padding-right: 100%;
			z-index: 333;
		}

	}

	&-section {
		padding-top: 35px;
		padding-bottom: 35px;

		&.dark .loyality__top:after {
			background-color: #f4f4f4;
		}
	}

	&__info {

		position: relative;
		left: -10px;

		transition: opacity ease-in-out 0.5s;

		@media (max-width: 1023px) {
			margin-bottom: 20px;
			left: 0;
		}

		&_heading {

			margin-bottom: 20px;

		}

		&_text {

			& .content {

				margin-bottom: 40px;

				& p {

					font-weight: 300;

				}

				& a {

					font-size: 18px;
	                font-weight: 300;
	                color: #3D8FE9;
	                text-decoration: underline;

	                transition: all ease-in-out 0.1s;

	                outline: none;

	                &:visited {
	                	color: #3D8FE9;
	                }

	                @media (max-width: 767px) {

	                    font-size: 16px;

	                }

				}

			}			

		}

		&_additions {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		&_logo {

			display: inline-block;

			position: relative;

			width: 180px;

			margin-right: 20px;

			& img {

				display: block;

				width: 100%;

			}

		}

		&_apps {

			display: inline-block;
			width: 150px;

			&-inner {

				display: -webkit-inline-flex;
				display: -moz-inline-flex;
				display: -ms-inline-flex;
				display: -o-inline-flex;
				display: inline-flex;
				flex-direction: column;
				justify-content: flex-start;


			}
			
			&-link {
				display: block;
				width: 150px;
				height: 50px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}
		}

	}

	&__slider {

		width: 100%;

		overflow: visible;

		& .swiper-lazy:not(.swiper-lazy-loaded) {

			max-height: 511px;

			@media (max-width: 1023px) {
				
				max-height: 0;

			}

		}

		&_wrapper {
			
			position: relative;
			
			width: 125%;
		}

		&_item {

			display: block;

			position: relative;

			overflow: hidden;

			padding-bottom: 53%;

			&:before {
				
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.5);

				transition: background ease-in-out 0.1s;

			}

			&.swiper-slide-active {

				&:before {

					background-color: transparent;

				}

			}

			& img {

				position: absolute;

				display: block;

				width: 100%;
				max-width: 100%;

			}

		}

	}

	&__controls {

		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		padding-top: 40px;
		padding-bottom: 40px;

		@media (max-width: 1200px) {

			padding-top: 20px;

		}

		@media (min-width: 1024px) and (max-width: 1024px) {

			position: relative;

			top: -85px;

		}

		@media (max-width: 579px) {

			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

		}

		&_counter {
			
			position: static;

			width: auto;

			color: #626262;

			margin-left: 35px;

			@media (max-width: 579px) {

				order: 1;

				margin-bottom: 10px;

				margin-left: 0;

			}

		}

		&_arrows {

			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;

			margin-left: 35px;

			@media (max-width: 579px) {

				order: 2;

				margin-bottom: 16px;
				margin-left: 0;

			}

			& .prev, & .next {

				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: center;
				align-items: center;

				width: 60px;
				height: 60px;

				background-color: #fff;

				-webkit-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
				box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);

				cursor: pointer;

			}

			& svg {

				display: block;

				width: 35px;
				height: 35px;

				fill: #7f7f7f;

				opacity: 1;

			}

			& .prev {

				margin-right: 20px;

				& svg {

					transform: rotate(180deg);

				}				

			}

			& .next {

				

			}			

		}

		&_backwards {

			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			margin-left: auto;

			opacity: 1;

			transition: opacity ease-in-out 0.1s;

			@media (max-width: 1023px) {
				
				display: none;

			}

			&-icon {

				width: 20px;
				height: 20px;

				margin-right: 10px;

				& svg {

					display: block;

					width: 20px;
					height: 20px;

					fill: @primary-color;

				}

			}

			&-link, & a {
				
				font-size: 18px;
				font-weight: 300;
				color: @primary-color;
				text-decoration: underline;

				transition: all ease-in-out 0.1s;

				outline: none;

				@media (max-width: 767px) {

					font-size: 16px;

				}

				&:hover {

					color: @primary-color-2;
					text-decoration: none;

				}

			}

		}

		&_download,
		&_conditions {

            margin-right: auto;
            position: relative;
            left: -10px;

            @media (max-width: 1024px) {
              left: 0;
            }

            @media (max-width: 579px) {

                order: 3;

                margin-right: 0;

            }

        }

	}

	&__form {
        
        color: #fff;
        background-color: @primary-color;

        &_wrapper {

            & .site-form {

                position: relative;
                
                @media (max-width: 579px) {

                    padding-right: 20px;
                    padding-left: 20px;

                }

                & label.error {

                  position: relative;
                  bottom: 0;

                }

                & .success {

                    position: absolute;
                    top: 0;
                    left: 0;

                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -ms-flex;
                    display: -o-flex;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
                    height: calc(100% + 40px);
                    
                    font-size: 22px;
                    font-weight: 300;

                    color: #fff;
                    background-color: @primary-color;

                    z-index: 222;

                    display: none;

                    & svg {

                        position: absolute;

                        top: 10px;
                        right: 0;

                        width: 20px;
                        height: 20px;

                        fill: #fff;

                        cursor: pointer;

                    }

                }

            }

            & .site-form__head {

                color: #fff;
                margin-bottom: 40px;

            }

            & .site-form__label {

                position: absolute;
                top: 18px;
                left: 25px;

                display: block;

                width: auto;

                font-size: 16px;
                font-weight: 300;
                color: #afb0b1;

                transition: all ease-in-out 0.2s;
                z-index: 0;

                &.top {

                  top: -19px;
                  left: 2px;
                  color: #fff;
                  font-size: 12px;

                }

            }

            & .error:not(.js-masked-input) {
                color: #ffffff !important;
                font-weight: 500 !important;
                font-style: italic !important;
            }

            & .site-form__input {

                font-family: @main-font;
                font-weight: 300;
                
                height: 60px;

                color: #afb0b1;
                background-color: #F3F3F3;
                border-color: #D6D6D6;

                &::placeholder {
                    color: #afb0b1;
                  }
                  &::-webkit-input-placeholder {
                    color: #afb0b1;
                  }
                  &::-moz-placeholder {
                    color: #afb0b1;
                  }
                  &:-moz-placeholder {
                    color: #afb0b1;
                  }
                  &:-ms-input-placeholder {
                    color: #afb0b1;
                  }
            }

            & .site-form__button-submit {

                width: 100%;

                font-family: @main-font;
                font-weight: 700;

                color: #000;
                background-color: #fff;

                border-width: 1px;
                border-color: #fff;

                @media (max-width: 1023px) {

                    width: auto;

                }

            }

            & .site-form__agreement {
                font-weight: 300;

                @media (max-width: 1023px) {

                    font-size: 14px;

                }
            }

        }

    }

}