/**
* modal form style
*
* Dependencies:
* 1. /src/components/wm.form/wm.form.less
* 2. /src/components/wm.buttons/wm.buttons.less
*/
.site-form-wrapper--modal {
  position: relative;
  background-color: #fff;
  max-width: 730px;
  margin: 15px auto;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  & .site-form__textarea {
    resize: none;
  }
}
.modal-form-message-info-wrapper {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  background-color: #fff;
  padding-left: 3.5%;
  padding-right: 3.5%;
  text-align: center;

  &.disabled {
    z-index: -1;
    visibility: hidden;
  }
}

.modal-form__message-info {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: @heading-color;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-form__message-caption {
  color: @text-color;
}

#callback-request {

  max-width: 520px;

  border-radius: 5px;

  & .site-form {
    
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    & label.error {

      position: absolute;
      left: 37px;
      bottom: -20px;

    }

    & .success {

      position: absolute;
      top: 0;
      left: 0;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
      
      font-size: 22px;
      font-weight: 300;

      color: #7f7f7f;
      background-color: #fff;

      z-index: 222;

      display: none;

    }

  }

  & .site-form__head {
    
    font-size: 25px;
    font-weight: 100;

    border-bottom: 1px solid #e6e6e6;

    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 37px;
    padding-right: 37px;
    margin-bottom: 0;

  }

  & .site-form-element-wrapper {
    
    padding-left: 37px;
    padding-right: 37px;

    margin-top: 20px;
    margin-bottom: 10px;

  }

  & .site-form__agreement {

    padding-bottom: 30px;
    padding-left: 37px;
    padding-right: 37px;

  }

  & .site-form__button-submit {
    
    font-weight: 300;
    width: 100%;

  }

  & .site-form__label {

    position: absolute;
    top: 11px;
    left: 53px;

    display: block;

    width: auto;

    font-size: 16px;
    font-weight: 300;
    color: #7f7f7f;

    transition: all ease-in-out 0.2s;
    z-index: 0;

    &.top {

      top: -19px;
      left: 37px;

      font-size: 12px;

    }

  }

  & .error:not(.js-masked-input) {

    color: @primary-color !important;
    font-weight: 500 !important;
    font-style: italic !important;
  }

  & .site-form__input {

    font-family: @main-font;
    font-weight: 300;
    
    height: 60px;

    color: #7f7f7f;
    background-color: #fff;
    border-color: #d6d6d6;

  }

  & .site-form__agreement {
    font-weight: 300;
    color: #7f7f7f;

    @media (max-width: 1023px) {

      font-size: 14px;

    }
  }

  & .mfp-close {

    top: 22px;
    right: 30px;

    @media (max-width: 767px) {
      
      top: 0;
      right: 0;

    }
    
    & .mfp-close__icon {

      width: 20px;
      height: 20px;

      fill: #7f7f7f;

    }

  }

}
