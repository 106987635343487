.partners {

	&-section {

		padding-top: 20px;
		padding-bottom: 20px;

		@media (max-width: 1023px) {

			padding-bottom: 40px;

		}

	}

	&__wrapper {

		position: relative;

	}

	&__slider {

		position: relative;

		overflow: hidden;

		height: 200px;

		@media (max-width: 1480px) {

			height: 140px;

		}

		& .swiper-lazy-preloader {
			
			z-index: -1;

		}

		&_item {
			
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			// height: 100%;

		}

		&_inner {

			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			position: relative;

			& img {

				display: block;
				
				width: 80%;

				@media (max-width: 1023px) {

					width: auto;
					
					max-width: 180px;
					max-height: 65px;

				}

			}

		}

	}

	&__controls {

		position: absolute;

		width: 100%;

		top: 50%;
		transform: translateY(-50%);
		left: 0;

		@media (min-width: 1024px) and (max-width: 1480px) {

			position: relative;
			top: unset;
			transform: none;
			left: unset;

			padding-top: 20px;

		}

		@media (min-width: 320px) and (max-width: 1023px) {

			position: relative;
			top: unset;
			transform: none;
			left: unset;

			padding-top: 10px;

		}

		&_arrows {

			position: static;

			width: 100%;

			@media (max-width: 1480px) {

				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: flex-end;

				height: 60px;

			}

			& .prev, & .next {

				position: absolute;

				top: 50%;
				transform: translateY(-50%);

				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: center;
				align-items: center;

				width: 60px;
				height: 60px;

				background-color: #fff;

				-webkit-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
				box-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);

				cursor: pointer;

				@media (max-width: 1480px) {

					position: static;
					top: unset;
					transform: none;

				}

			}

			& svg {

				display: block;

				width: 35px;
				height: 35px;

				fill: #7f7f7f;

				opacity: 1;

			}

			& .prev {

				left: -65px;

				@media (max-width: 1480px) {

					left: unset;

					margin-right: 20px;

				}

				& svg {

					transform: rotate(180deg);

				}				

			}

			& .next {

				right: -65px;

				@media (max-width: 1480px) {

					right: unset;

				}

			}	

		}

	}

}