.slider-top {

  &-section {

    border-bottom: 2px solid #bfbfbf;

    overflow-x: hidden;

    & > .container {
      max-width: none;
    }

  }

  &__wrapper {

    position: relative;

    width: 100%;
    height: 684px;

    @media (max-width: 1024px) {
      
      height: 450px;

    }    

  }

  &__controls {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    width: 100%;

    padding-left: 45px;
    padding-right: 45px;

    z-index: 444;

    @media (max-width: 767px) {
      display: none;
    }

    &_inner {

      position: relative;

      height: 45px;

    }

    &_prev, &_next {

      position: absolute;
      top: 0;

      width: 25px;
      height: 45px;

      cursor: pointer;

      & svg {
        width: 25px;
        height: 45px;

        fill: #e8e4e1;
      }

    }

    &_prev {
  
      left: 0;

      @media (max-width: 1024px) {
        left: -30px;
      }

    }

    &_next {
      
      right: 0;
      transform: rotate(180deg);

      @media (max-width: 1024px) {
        right: -30px;
      }

    }

  }

  &__inner {

    height: 684px;

    @media (max-width: 1024px) {
      
      height: 450px;

    }

    & .slick-dots {

      bottom: 20px;

      & button {

        position: relative;

        width: 22px;
        height: 22px;

        border: 2px solid #fff;

        &:before {

          content: '';
          
          width: 10px;
          height: 10px;
          background-color: transparent;

        }

      }

      & .slick-active button:before {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        background-color: @primary-color;

      }

    }

  }

  &__item {

    height: 684px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {

      content: '';

      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;

      background-color: rgba(0,0,0,0.25);

    }

    @media (max-width: 1024px) {
      
      height: 450px;

    }

    &_content {

      position: relative;
      top: 50%;
      transform: translateY(-50%);

      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
      text-align: center;

      z-index: 555;

    }

    &_heading {

      font-size: 55px;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;

      padding-top: 45px;
      padding-bottom: 45px;

      @media (min-width: 1025px) and (max-width: 1199px) {

        font-size: 44px;

      }

      @media (min-width: 768px) and (max-width: 1024px) {

        font-size: 36px;

      }

      @media (min-width: 320px) and (max-width: 767px) {
        
        font-size: 24px;
        
      }

    }

    &_btn {

      

    }

  }

}