.capabilities {
  &-show-more {
    max-width: 280px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    @media screen and (max-width: 920px) {
      margin-bottom: 25px;
    }

    &-slider {
      max-width: 100%;
      width: 100%;

      &-slide {
        display: block;
        max-width: 100%;

        & img {
          display: block;
          height: 100%;
          width: 100%;
          max-width: 100%;
          max-height: 320px;
          height: 320px;
          object-fit: cover;
          margin: 0 auto;
        }
      }

      &-arrows {
        &__prev, &__next {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          border-radius: 154px;
          background-color: rgba(0, 0, 0, .3);
          z-index: 9;
          cursor: pointer;
          transition: all .3s ease-in-out;

          & svg {
            width: 9px;
            height: 17px;
          }

          &:hover {
            background-color: rgba(0, 0, 0, .75);
          }
        }

        &__prev {
          left: 20px;

          & svg {
            margin-left: -4px;
          }
        }

        &__next {
          right: 20px;

          & svg {
            margin-right: -4px;
          }

          & svg {
            transform: rotate(180deg);
          }
        }
      }

      & .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
        background-color: #ffffff;
        opacity: 0.5;
        transition: all .3s ease-in-out;
        margin: 0 9px !important;

        &-active {
          opacity: 1;
        }
      }

      &-photo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 51px;
        height: 30px;
        border-radius: 4px;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.5);

        &__icon {
          width: 17px;
          height: 15px;
          background-image: url('/assets/front/img/capabilities/photo.svg');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &__counter {
          opacity: 0.8;
          color: #ffffff;
          font-family: @main-font;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          margin-top: 3px;
        }
      }
    }

    &__caption {
      display: block;
      margin-top: 25px;
      color: #000000;
      font-family: @main-font;
      font-size: 20px;
      font-weight: 600;
      line-height: 31px;
      @media (max-width: 920px) {
        margin-top: 15px;
      }
    }

    &__description {
      display: block;
      margin-top: 5px;
      color: #000000;
      font-family: @main-font;
      font-size: 17px;
      font-weight: 300;
      line-height: 25px;
    }
  }
}
